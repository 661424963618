import urlParser from 'bloko/common/urlParser';

import statsSender from 'HHC/Performance/StatsSender';

const currentHost = window.location.host;
const availableExtensions = ['js', 'css', 'png', 'svg'];

const resourceDurationMapByHost = {};

const durationStaticLoad = () => {
    if (!('PerformanceObserver' in window && PerformanceObserver.supportedEntryTypes?.includes?.('resource'))) {
        return;
    }

    try {
        new PerformanceObserver((entryList) => {
            const entries = entryList.getEntries();

            for (const entry of entries) {
                const resourcePath = entry.name;
                const fileExt = resourcePath.split('.').pop();

                if (
                    typeof fileExt === 'string' &&
                    (resourcePath.includes(currentHost) || resourcePath.includes(window.globalVars.staticHost)) &&
                    availableExtensions.includes(fileExt) &&
                    !resourcePath.includes('-sw.js') &&
                    entry.responseStatus === 200
                ) {
                    const { host } = urlParser(resourcePath);
                    const mapByHost = (resourceDurationMapByHost[host] = resourceDurationMapByHost[host] ?? {});

                    if (!mapByHost[fileExt]) {
                        mapByHost[fileExt] = [];
                    }

                    mapByHost[fileExt].push(entry.duration);
                }
            }
        }).observe({
            type: 'resource',
            buffered: true,
        });
    } catch (_ignore) {} // eslint-disable-line
};

window.onload = () => {
    Object.entries(resourceDurationMapByHost).forEach(([host, timingsByFileExt]) => {
        const result = Object.entries(timingsByFileExt).reduce((result, [ext, duration]) => {
            result[ext] =
                duration.reduce((result, value) => {
                    result += value;
                    return result;
                }, 0) / duration.length;

            return result;
        }, {});

        const averageValue =
            Object.values(result).reduce((result, value) => {
                result += value;
                return result;
            }, 0) / Object.values(result).length;

        statsSender.sendMetrics({
            metricName: 'dsl',
            valueInMs: averageValue,
            staticHost: host,
        });
    });
};

export default durationStaticLoad;
