import BlokoComponents from 'bloko/common/core/Components';

import Debug from 'HHC/Debug';

/**
 * Модуль реалзующий компоненты
 *
 * Больше информации по компонентам тут
 * {@link https://github.com/hhru/xhh/blob/master/docs/js/Components.md}
 *
 * @type {Object}
 * @exports HHC/Components
 */
const Components = Object.create(BlokoComponents);
let importFunction;
/**
 * Вешает на элемент компонент с параметрами.
 *
 * @param {Object}  component Объект-конструктор компонента
 * @param {Element} element   Элемент на котором проинициализировать компонент
 * @param {Object}  [params]  Параметры с которыми проинициализировать компонент
 * @param {String}  [componentName] Название компонента - внутренний параметр , используется для логирования
 *
 * @returns {сomponentInstance} Интерфейс компонента
 *
 * @member
 * @method
 */
Components.make = function (component, element, params, componentName) {
    Debug.log('components:make', {
        name: componentName,
        element,
        params,
    });
    // Из-за бага в rjs при котором возможно неожиданное получение undefined или объекта из модуля
    if (typeof component !== 'function') {
        Debug.log('error', 'PORTFOLIO-2272 findout', {
            componentName,
            params,
            typeof: typeof component,
        });
        return undefined;
    }
    // eslint-disable-next-line prefer-rest-params
    return BlokoComponents.make.apply(this, [...arguments]);
};
Components.setInitFunction = (func) => {
    importFunction = func;
};
Components.init = function (element, importFunc) {
    return BlokoComponents.init.apply(this, [element, importFunc || importFunction]);
};

const deferredBuilds = [];

let shouldBuildImmediately = false;

Components.buildDeferredComponents = () => {
    shouldBuildImmediately = true;
    deferredBuilds.forEach((build) => build());
};

Components.build = (component) => (element, params, fromMake) => {
    const reactRootNode = document.getElementById('HH-React-Root');
    if (!reactRootNode || reactRootNode.dataset.ssrStatus !== 'fail' || shouldBuildImmediately) {
        return BlokoComponents.build(component)(element, params, fromMake);
    }
    deferredBuilds.push(() => {
        return BlokoComponents.build(component)(element, params, fromMake);
    });
    return undefined;
};
export default Components;
