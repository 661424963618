import { AxiosResponse } from 'axios';

import { AnalyticsParams, sendPostRequest } from '@hh.ru/analytics-js/lib/hhAnalytics';

import axios from 'HHC/Axios';

interface SendHHAnalyticsParams {
    goal?: string;
    data?: Partial<Record<string, string>>;
}
/**
 * Отправляет запрос на /analytics с произвольным набором данных
 * Данные по событиям попадут в hadoop в таблицу log.access
 *
 * @deprecated Таблица log.access большая и искать в ней такие события долго. Для аналитики рекомендуется использовать
 * методы sendHHEvent, sendHHEventButtonClick или sendHHEventElementShown
 * из пакета @hh.ru/analytics-js. Такие события попадают в hadoop в таблицу kafka_site_raw.analytics_request.
 * Затем раз в день переливаются в kafka_site.analytics_request
 *
 * @param {Object} params           произвольные аналитические параметры, которые будут переданы в URL
 * @param {Object} [params.data]    параметры события
 * @param {String} [params.goal]    название события
 * @param {Object} bodyData         опциональные параметры (form-data), отсылаемые в теле запроса
 */
function SendHHAnalytics(
    { goal, data }: SendHHAnalyticsParams,
    bodyData: AnalyticsParams = {}
): Promise<void | AxiosResponse> {
    const params = data || { goal };
    return sendPostRequest(params, bodyData);
}

interface SendKardinalAnalyticsParams {
    goal: string;
    data: Partial<Record<string, string | number>>;
}

/**
 * Отправляет событие в kardinal
 *
 * @deprecated Для аналитики рекомендуется использовать методы
 * sendHHEvent, sendHHEventButtonClick или sendHHEventElementShown из пакета @hh.ru/analytics-js
 */
export function SendKardinalAnalytics({ goal, data }: SendKardinalAnalyticsParams): Promise<AxiosResponse> {
    return axios.get(`/tracking/${goal}`, { params: data });
}

export default SendHHAnalytics;
