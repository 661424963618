import Supports from 'bloko/common/supports';

import Debug from 'HHC/Debug';

/**
 * Модуль регистрирует обработчик для отладочной утилиты {@link module:HHC/Debug},
 * служит для кроссбраузерного отображения подробного объекта Error в консоли с кликабельным стектрейсом. <br>
 *
 * Имя обработчика - "error", Уровень логирования - PRODUCTION
 *
 * @example
 * Debug = require('HHC/Debug');
 * Debug.log('error', new Error('Ошибка!'))
 *
 * @param {...Error} args Объект ошибки для вывода
 * @type {Function}
 * @exports HHC/Debug/Error
 */
const handler = function (...args) {
    args.forEach((error, index) => {
        if (error instanceof Error) {
            // В вебкитах стек выводится не от ошибки, а от места где произошел throw,
            // по-этому заменяем выстрел throw на вывод error.stack.
            if (Supports.webkit() && error.stack) {
                // В хроме <43 error.stack должен быть выброшен в отдельном console.error
                console.error(error.stack);
            } else {
                // Оборачиваем в асинхронный контекст, чтобы не взрывать рантайм дебага.
                window.setTimeout(() => {
                    window.globalVars.blockNextErrorSend = true;
                    throw error;
                }, 0);
            }
            args.splice(index, 1);
        }
    });
    if (args.length === 0) {
        return;
    }
    console.error(JSON.stringify(...args));
};
Debug.registerLog('error', Debug.viewMods.PRODUCTION, handler);
