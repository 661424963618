import $ from 'jquery';

import Cookies from 'bloko/common/Cookies';

const safeMethods = ['GET', 'HEAD', 'OPTIONS', 'TRACE'];
function xsrfSafeMethod(method) {
    return safeMethods.indexOf(method.toUpperCase()) !== -1;
}
export default function () {
    $.ajaxPrefilter((options) => {
        if (!xsrfSafeMethod(options.type)) {
            options.headers = options.headers || {};
            options.headers['X-Xsrftoken'] = Cookies.get('_xsrf');
        }
    });
}
