import pushRequestIdToSentry from 'Modules/pushRequestIdToSentry';

const successInterceptor = (response) => {
    const xRequestId = response.headers['x-request-id'];
    pushRequestIdToSentry(xRequestId);

    return response;
};

export default successInterceptor;
