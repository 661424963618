import $ from 'jquery';

import { getHhtmHeaders } from 'Modules/hhtm';

export default function () {
    $.ajaxPrefilter((options) => {
        const hhtmHeaders = getHhtmHeaders(options.url);
        options.headers = { ...hhtmHeaders, ...options.headers };
    });
}
