const makeFingerPrintPromise = () => {
    let _resolve;
    let _reject;
    const promise = new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    });
    return { resolve: _resolve, reject: _reject, then: promise.then.bind(promise), catch: promise.catch.bind(promise) };
};

export const fingerprintPromise = makeFingerPrintPromise();
export const securePortalFingerprintPromise = makeFingerPrintPromise();
