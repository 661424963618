import SendHHAnalytics from 'HH/SendHHAnalytics';

const FINGERPRINT_CONFIG = {
    excludes: {
        screenResolution: true,
        hasLiedResolution: true,
        fonts: true,
        webglVendorAndRenderer: true,
        audio: true,

        // Умолчанию из самой библиотеки, нужно указывать явно,
        // потому что merge свойств не глубокий, смотрит только на первый уровень в объекте конфига
        enumerateDevices: true,
        pixelRatio: true,
        doNotTrack: true,
        fontsFlash: true,
    },
};
function sendFingerprint(fingerprint) {
    const data = {
        ...fingerprint,
        userType: window.globalVars.userType,
        requestId: window.globalVars.requestId,
        hhid: window.globalVars.hhid,
    };
    SendHHAnalytics({ data });
}
export default {
    init() {
        return new Promise((resolve) => {
            window.requestIdleCallback(() => {
                import('fingerprint2')
                    .then(({ default: Fingerprint2 }) => {
                        try {
                            const executionStart = performance.now();
                            Fingerprint2.get(FINGERPRINT_CONFIG, (components) => {
                                const values = components.map((component) => component.value);
                                const fingerprintFull = Fingerprint2.x64hash128(values.join(''), 31);
                                resolve(fingerprintFull);
                                const totalExecutionTime = performance.now() - executionStart;
                                SendHHAnalytics({
                                    data: {
                                        goal: 'fingerprinting_done',
                                        took: totalExecutionTime,
                                    },
                                });
                                sendFingerprint({ fingerprintFull });
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    })
                    .catch(console.error);
            });
        });
    },
    sendFingerprint,
};
