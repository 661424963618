import $ from 'jquery';

import {
    PHONE_VERIFICATION_ERROR,
    isAtPhoneVerificationPage,
    redirectToPhoneVerification,
} from 'Modules/PhoneVerificationInterceptor';

const phoneVerificationJqueryInterceptor = () => {
    $(document).ajaxError((event, xhr) => {
        if (
            xhr?.status === 403 &&
            xhr.responseJSON?.error === PHONE_VERIFICATION_ERROR &&
            !isAtPhoneVerificationPage()
        ) {
            redirectToPhoneVerification();
        }
    });
};

export default phoneVerificationJqueryInterceptor;
