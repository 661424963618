import scrollToElement from 'bloko/common/scrollToElement';

import { qaState, SCROLL_PROCESSING } from 'Modules/qaState';

export default (...[element, params = {}]: Parameters<typeof scrollToElement>): void => {
    const { animationStartCallback, animationEndCallback, ...rest } = params;
    scrollToElement(element, {
        ...rest,
        animationStartCallback: () => {
            qaState[SCROLL_PROCESSING] = true;
            animationStartCallback?.();
        },
        animationEndCallback: () => {
            qaState[SCROLL_PROCESSING] = false;
            animationEndCallback?.();
        },
    });
};
