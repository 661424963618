/**
 * Реестр данных для автотестов
 */

export const SCROLL_PROCESSING = 'scrollTopProcessing';
export const AXIOS_REQUEST = 'axiosRequests';
export const COMPONENTS_INIT_END = 'autotestsComponentsInitEnd';
export const JS_ERRORS = 'errors';

const state = {
    [JS_ERRORS]: [],
    [AXIOS_REQUEST]: 0,
};

if (typeof window !== 'undefined' && !window.qaState) {
    window.qaState = state;
}

function wrapState(state: Record<string, unknown>) {
    // Proxy для lux нужен, чтобы не было утечек памяти.
    // Он просто игнорирует любую запись, что дает возможность не беспокоиться,
    // что мы когда-нибудь переполним инстанс из-за ошибок
    if (process.env.SSR) {
        // eslint-disable-next-line no-restricted-syntax
        return new Proxy(state, {
            get(target, prop: string) {
                const field = target[prop];
                if (field && typeof field === 'object') {
                    if (field === null) {
                        return null;
                    }
                    if (Array.isArray(field)) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
                        return [...field];
                    }
                    return { ...field };
                }
                return field;
            },
            set() {
                return true;
            },
        });
    }

    return state;
}
export const qaState = wrapState(state);
