import sendHHAnalytics from 'HH/SendHHAnalytics';

import { NORMAL_MODE, detectBrowserMode } from 'HHC/CheckBrowser/BrowserMode/Detector';

const sendAnalytics = (browserMode) => {
    const data = {
        userType: window.globalVars.userType,
        requestId: window.globalVars.requestId,
        hhid: window.globalVars.hhid,
        ...browserMode,
    };
    sendHHAnalytics({ data });
};

export default () => {
    detectBrowserMode()
        .then((browserMode) => {
            if (browserMode.mode !== NORMAL_MODE && window.globalVars.userType !== 'anonymous') {
                sendAnalytics(browserMode);
            }
        })
        .catch(console.error);
};
