import { SetOptional } from 'type-fest';

interface OnOptions {
    origin?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handlers: Record<string, (data: any, event: MessageEvent) => void>;
}

interface MessageContainer {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

interface TriggerOptions {
    origin?: string;
    target: Window;
    message: SetOptional<MessageContainer, 'data'>;
}

/**
 * Утилита по работе с postMessage интерфейсом.
 * @type {Object}
 * @exports Utils/PostMessage
 */
const PostMessage = {
    /**
     * Слушает postMessage сообщения
     *
     * @param {Object} options
     * @param {Object} options.handlers Объект с обработчиками, где ключ: имя события, значение: функция-обработчик
     *                                  присланные данные приходят в обработчик первым аргументом
     * @param {String} [options.origin=window.location.origin] Проверочный урл окна-источника сообщения
     *
     * @member
     * @method
     */
    on(passedOptions: OnOptions): () => void {
        const defaultOptions = { origin: window.location.origin };
        const options: OnOptions = {
            ...defaultOptions,
            ...passedOptions,
        };
        const listener = (event: MessageEvent) => {
            if (event.origin !== options.origin || typeof event.data !== 'string') {
                return;
            }
            let data: MessageContainer;
            try {
                data = JSON.parse(event.data) as MessageContainer;
            } catch (_) {
                return;
            }
            if (data && options.handlers[data.name] instanceof Function) {
                options.handlers[data.name](data.data, event);
            }
        };
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    },
    /**
     * Отсылает postMessage сообщение
     *
     * @param {Object} options
     * @param {Window} options.target          Объект окна-адресата
     * @param {String} options.message.name    Имя события
     * @param {Object} options.message.data    Данные для отсылки
     * @param {String} [options.origin='*']    Проверочный урл окна-адресата
     *
     * @member
     * @method
     */
    trigger(options: TriggerOptions): void {
        options.target.postMessage(JSON.stringify(options.message), options.origin || '*');
    },
};
export default PostMessage;
