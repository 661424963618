import Fingerprint from 'HH/Fingerprint';
import { fingerprintPromise, securePortalFingerprintPromise } from 'HH/FingerprintPromises';
import intruderDetection from 'HH/IntruderDetection';
import SendHHAnalytics from 'HH/SendHHAnalytics';

const DOMAINS_WITH_ENABLED_SECURE_PORTAL = ['hh.kz', 'hh.ru', 'hh.uz', 'zarplata.ru'];

function initSecurePortal() {
    const { features } = window.globalVars;
    if (
        features.secure_portal_enabled === true &&
        DOMAINS_WITH_ENABLED_SECURE_PORTAL.some((domain) => window.location.hostname.endsWith(domain))
    ) {
        window.requestIdleCallback(() => {
            let executionStart;
            window.onSecurePortalStarted = function (securePortal) {
                executionStart = performance.now();
                securePortal.setLogin(window.globalVars.login);
                securePortal.setUserId(window.globalVars.hhid);
                fingerprintPromise
                    .then((fingerprint) => {
                        securePortal.setAdditionalParams({ fingerprint });
                    })
                    .catch(console.error);
            };
            window.onSecurePortalFingerprintDone = function (fingerprintSP) {
                const totalExecutionTime = performance.now() - executionStart;
                SendHHAnalytics({
                    data: {
                        goal: 'sp_fingerprinting_done',
                        took: totalExecutionTime,
                    },
                });
                Fingerprint.sendFingerprint({ fingerprintSP });
                securePortalFingerprintPromise.resolve(fingerprintSP);
            };
            if (features.secure_portal_test_version_enabled === true) {
                import('secureportal.test_ver');
            } else {
                import('secureportal.v29');
            }
        });
    }
}

function init() {
    const { features } = window.globalVars;
    if (window.globalVars.userType === 'employer') {
        intruderDetection();
    }

    // У некоторых пользователей не успевает вычислиться FP и непонятно,
    // боты это или просто он не успел вычислиться до перехода на другую страницу.
    // По этому собираем appVersion перед вычислением FP с целью убедиться что
    // на стороне пользователя работает JavaScript.
    Fingerprint.sendFingerprint({ fpAppVersion: window.navigator.appVersion });
    const isAnonymous = window.globalVars.userType === 'anonymous';
    const fingerprintEnabledForAuthorized = features.fingerprinting_enable && !isAnonymous;
    const fingerprintEnabledForAnonymous = features.fingerprinting_for_anonymous_user_enable && isAnonymous;

    if (fingerprintEnabledForAuthorized || fingerprintEnabledForAnonymous) {
        Fingerprint.init()
            .then((fingerprint) => {
                fingerprintPromise.resolve(fingerprint);
            })
            .catch(console.error);
    }

    initSecurePortal();
}
export default {
    init,
};
