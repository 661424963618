import Cookies from 'bloko/common/Cookies';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import notifyAboutValueChange from 'HHC/CheckBrowser/HHUID/notifyAboutValueChange';

const KEY_NAME = 'hhuid';

export default () => {
    const cookieHHUID = Cookies.get(KEY_NAME);
    const storageHHUID = LocalStorageWrapper.getItem(KEY_NAME);

    if (storageHHUID === null) {
        LocalStorageWrapper.setItem(KEY_NAME, cookieHHUID);
        return;
    }

    if (cookieHHUID !== storageHHUID) {
        notifyAboutValueChange(cookieHHUID, storageHHUID, 'localStorage');

        LocalStorageWrapper.setItem(KEY_NAME, cookieHHUID);
    }
};
