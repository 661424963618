let _luxSentryBeforeSend;

export const getLuxSentryBeforeSend = () => _luxSentryBeforeSend;
export const setLuxSentryBeforeSend = (luxSentryBeforeSend) => {
    _luxSentryBeforeSend = luxSentryBeforeSend;
};

export default () => {
    if (window.globalVars.luxEnabled) {
        // eslint-disable-next-line
        import(/* webpackChunkName: "App" */ 'src/app/index.jsx');
    }
};
