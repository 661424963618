import AccountLoginInterceptor from 'Modules/AccountLoginInterceptor';
import PhoneVerificationInterceptor from 'Modules/PhoneVerificationInterceptor';
import prepareAxiosErrorDataInterceptor from 'Modules/prepareAxiosErrorDataInterceptor';
import pushRequestIdToSentry from 'Modules/pushRequestIdToSentry';

const errorInterceptor = (error) => {
    const xRequestId = error?.response?.headers['x-request-id'];
    prepareAxiosErrorDataInterceptor(error);
    pushRequestIdToSentry(xRequestId);

    return PhoneVerificationInterceptor(error).catch(AccountLoginInterceptor);
};

export default errorInterceptor;
