import axios, { AxiosRequestConfig } from 'axios';

import 'Modules/AxiosSettings';
import prepareFormData, { PostFormData } from 'Modules/prepareFormData';

export default Object.assign({}, axios, {
    /**
     * POST запрос, который процессит все JSON данные в виде FormData.
     * В этом случае в body будет сериализован не в JSON,
     * а в формат `multipart/form-data`
     *
     * @method
     * @name postFormData
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные, которые будут переданы в body запроса
     * @param {Object} [config] axios конфиг, подробнее здесь https://github.com/axios/axios#request-config
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    postFormData: (url: string, data: FormData | PostFormData, config?: AxiosRequestConfig) => {
        return axios.post(url, prepareFormData(data), config);
    },
    /**
     * PUT запрос, который процессит все JSON данные в виде FormData.
     * В этом случае в body будет сериализован не в JSON,
     * а в формат `multipart/form-data`
     *
     * @method
     * @name putFormData
     * @param {string} url адрес, на который будет совершен запрос
     * @param {Object} [data] данные, которые будут переданы в body запроса
     * @param {Object} [config] axios конфиг, подробнее здесь https://github.com/axios/axios#request-config
     * @returns {Promise} промис зарезолвится c данными, если ответ 200 <= answer < 300 и зареджектится в других случаях
     */
    putFormData: (url: string, data: FormData, config: AxiosRequestConfig) => {
        return axios.put(url, prepareFormData(data), config);
    },
});
