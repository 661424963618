import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'HHC/Init';

// Указываем, что корневой модуль может обновляться при module replacement
// без этого не обновляются родительские компоненты при изменении дочернего + видим варнинги
if (module.hot) {
    module.hot.accept();
}
