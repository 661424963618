import StatsSender from '@hh.ru/stats-sender';
import Cookies from 'bloko/common/Cookies';

const sender = new StatsSender({
    app: 'xhh',
    globalParamsGetter: () => ({
        isWebView: window.globalVars.isWebView,
        pageName: window.globalVars.luxPageName,
        xsrf: Cookies.get('_xsrf') || '',
    }),
    url: '/shards/stat',
    beforeSend: () => !!window.globalVars.features.stat_shard_request_percent,
});

export default sender;
