function timeToInteractive(complete) {
    // Ожидаемое время TTI
    const LONG_TASK_TIME = 2000;
    // Максимально ожидаемое время TTI, если не произошло лонгтасок
    const MAX_LONG_TASK_TIME = 30000;

    if ('PerformanceObserver' in window && 'PerformanceLongTaskTiming' in window) {
        let timeoutIdCheckTTI;
        const longTask = [];
        const observer = new window.PerformanceObserver((list) => {
            for (const entry of list.getEntries()) {
                longTask.push(Math.round(entry.startTime + entry.duration));
            }
        });

        observer.observe({ entryTypes: ['longtask'] });

        const checkTTI = () => {
            if (longTask.length === 0 && performance.now() > MAX_LONG_TASK_TIME) {
                clearTimeout(timeoutIdCheckTTI);
            }

            const eventTime = longTask[longTask.length - 1];

            if (eventTime && performance.now() - eventTime >= LONG_TASK_TIME) {
                complete(eventTime);
            } else {
                timeoutIdCheckTTI = setTimeout(checkTTI, LONG_TASK_TIME);
            }
        };

        checkTTI();
    }
}

export default timeToInteractive;
