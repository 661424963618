import $ from 'jquery';

import Debug from 'HHC/Debug';

/**
 * Модуль регистрирует обработчик для отладочной утилиты {@link module:HHC/Debug}. <br>
 * Отслеживает навешивание компонента событием `components:make` <br>
 * Debug-утилита `components` отображает все компоненты на странице, <br>
 * по клику на лейбл, выводит в консоль подробную информацию о компоненте.
 *
 * @example
 * jsDebug.utility.components.open()
 *
 * @exports HHC/Debug/Components
 */
let makeComponentHandler = function () {};
const components = [];
let $labels = $();
let $elements = $();
const classNames = {
    componentNode: 'debug-components',
    label: 'debug-components__label',
};
Debug.registerLog('components:make', Debug.viewMods.PRODUCTION, (component) => {
    components.push(component);
    makeComponentHandler(component);
});
Debug.registerUtility('components', {
    open() {
        const processComponent = function (component) {
            const $label = $('<div>').addClass(classNames.label).text(component.name);
            $labels = $labels.add($label);
            const $element = $(component.element);
            $elements = $elements.add($element);
            $element.addClass(classNames.componentNode);
            $element.append($label);
            $label.on('click', () => {
                // eslint-disable-next-line no-console
                console.log(component.name, component.params, component.element);
            });
        };
        components.forEach(processComponent);
        makeComponentHandler = processComponent;
    },
    close() {
        $labels.remove();
        $elements.removeClass(classNames.componentNode);
    },
});
