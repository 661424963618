import 'core-js/proposals/url';
import $ from 'jquery';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import { setServiceName } from '@hh.ru/front-static-app';
import configureAndInitSentry from '@hh.ru/sentry-js';
import cookies from 'bloko/common/Cookies';

import SecureAnalytics from 'HH/SecureAnalytics';
import { floodlightProcessPostponed } from 'HH/SendFloodlightConversion';
import PostMessage from 'Modules/PostMessage';
import { qaState, COMPONENTS_INIT_END, JS_ERRORS } from 'Modules/qaState';

import AnchorScroll from 'HHC/AnchorScroll';
import checkBrowser from 'HHC/CheckBrowser';
import hhtm from 'HHC/Common/hhtm';
import xsrf from 'HHC/Common/xsrf';
import Components from 'HHC/Components';
import DebugSetup from 'HHC/Debug/Setup';
import initLuxApp, { getLuxSentryBeforeSend } from 'HHC/LuxInit';
import durationStaticLoad from 'HHC/Performance/DurationStaticLoad';
import statsSender from 'HHC/Performance/StatsSender';
import timeToInteractive from 'HHC/Performance/TimeToInteractive';
import 'HHC/Performance/WebVitals';
import phoneVerificationJqueryInterceptor from 'HHC/PhoneVerificationJqueryInterceptor';
import importFunction from 'HHC/importFunction';
import 'HHC/windowOpenLogging';

setServiceName('xhh-js');

function _extends() {
    const _extends = function (target) {
        for (let i = 1; i < arguments.length; i++) {
            // eslint-disable-next-line prefer-rest-params
            const source = arguments[i];
            for (const key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };

    // eslint-disable-next-line prefer-rest-params
    return _extends.apply(this, arguments);
}
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isOpenedInIFrame = window.top !== window;

if (isSafari) {
    /* Удалить костыль после исправления работы в safari
     * https://github.com/final-form/final-form/issues/186#issuecomment-1735111004
     */
    Object.assign = _extends;
}

/* Нужно для того чтобы все возможные bloko модули (кроме иконок) попали в SharePlugin */
if (window.forceTreeShaking) {
    // eslint-disable-next-line
    import('bloko');
}

// Устанавливаем флаг выполнения Init
window.globalVars.jsExecuted = 1;

// Восстанавливаем дефолтное поведение $.htmlPrefilter
const rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
$.htmlPrefilter = function (html) {
    return html.replace(rxhtmlTag, '<$1></$2>');
};

const publicBuildPath = `${window.globalVars.staticHost}/${window.globalVars.buildFolder}/`;

/* eslint-disable */
__webpack_public_path__ = publicBuildPath;
/* eslint-enable */

configureAndInitSentry({
    sentryLoggingEnabled: window.globalVars.features.sentry_logging,
    sentryJsConfig: window.globalVars.features.sentry_js_config,
    sentryDSN: window.globalVars.sentryDSN,
    buildVersion: `xhh@${window.globalVars.build}`,
    staticHost: window.globalVars.staticHost,
    publicBuildPath,
    jsBuildFilterKey: 'sentry-js-hh-filter-key',
    getUser: () => ({
        id: cookies.get('hhuid'),
        userType: window.globalVars.userType,
    }),
    getExtraData: () => ({
        ...window.globalVars.sentryExtraData,
        requestId: window.globalVars.requestId,
        pageName: window.globalVars.luxPageName,
    }),
    beforeSendCallback: (event) => {
        if (window.globalVars.blockNextErrorSend) {
            window.globalVars.blockNextErrorSend = false;
            return false;
        }

        const luxBeforeSend = getLuxSentryBeforeSend();
        if (luxBeforeSend) {
            luxBeforeSend(event);
        }

        qaState[JS_ERRORS].push(event);

        return true;
    },
    excludeStoreKeys: [
        'trl',
        'features',
        'footer',
        'headerMenu',
        'areaTree',
        'profareaTree',
        'industriesTree',
        'professionalRoleTree',
        'skillsListsByRole',
    ],
});

timeToInteractive((eventTime) => {
    if (window.globalVars?.performance?.pageWasActive) {
        statsSender.sendMetrics({ metricName: 'tti-with-visibility-api', valueInMs: eventTime });
    }
});
hhtm();
SecureAnalytics.init();
xsrf();
DebugSetup.install();
phoneVerificationJqueryInterceptor();
checkBrowser();

document.addEventListener('DOMContentLoaded', () => {
    window.globalVars.bodyIsExisted = !!document.body;

    initLuxApp();
    const componentsInitStart = performance.now();

    Components.setInitFunction(importFunction);
    Components.init(document, importFunction).always(() => {
        const componentsInitTime = Math.floor(performance.now() - componentsInitStart);
        statsSender.addTimings({ componentsInitTime }).sendNavigationTimings();
        qaState[COMPONENTS_INIT_END] = true;
        window.globalVars.autotestsComponentsInitEnd = true;
        window.requestIdleCallback(VendorAnalytics.processPostponed);
        window.requestIdleCallback(floodlightProcessPostponed);
        window.requestIdleCallback(durationStaticLoad);
        AnchorScroll();
    });

    if (isOpenedInIFrame && window.top) {
        PostMessage.trigger({
            target: window.top,
            message: { name: 'iframeContentLoaded' },
        });
    }
});
