(function () {
    const open_ = window.open;
    window.open = function (url) {
        new Image().src = `${window.location.protocol}//${window.location.hostname}/analytics?windowOpen=${encodeURI(
            url
        )}`;
        // eslint-disable-next-line prefer-rest-params
        return open_.apply(this, arguments);
    };
})();
