import urlParser from 'bloko/common/urlParser';

const defaultHeadersInterceptor = (config) => {
    const parsedUrl = urlParser(config.url);

    if (!parsedUrl.hostname || parsedUrl.hostname === window.location.hostname) {
        config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    }
    return config;
};

export default defaultHeadersInterceptor;
