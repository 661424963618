import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import Debug from 'HHC/Debug';

const storageDetected = LocalStorageWrapper.getItem('__gclprv') !== null;
let extensions;
let chromeExtensions;
let domElementsDetected = false;

try {
    extensions = JSON.parse(window.globalVars.features.employer_extensions_to_detect);
} catch (e) {
    Debug.log('out error', new Error('Настройка employer_extensions_to_detect не является JSONом'));
}

try {
    chromeExtensions = JSON.parse(window.globalVars.features.employer_chrome_extensions_to_detect);
} catch (e) {
    Debug.log('out error', new Error('Настройка employer_chrome_extensions_to_detect не является JSONом'));
}

for (const ext in extensions) {
    if (document.getElementById(extensions[ext]) || document.getElementsByClassName(extensions[ext]).length > 0) {
        domElementsDetected = ext;
        break;
    }
}

const isChrome = () => window?.bloko?.getUserAgentDetails(window.navigator.userAgent).browser === 'chrome';

const detectChromeExtensions = () => {
    if (!isChrome()) {
        return;
    }

    chromeExtensions?.forEach(async ({ name, url }) => {
        let response;
        try {
            response = await fetch(url);
        } catch {
            return;
        }
        if (response?.ok) {
            sendAnalytics({ storageDetected, domElementsDetected, extensionName: name });
        }
    });
};

detectChromeExtensions();

function sendAnalytics({ storageDetected, domElementsDetected, extensionName }) {
    const analyticsGetArguments = [];
    analyticsGetArguments.push(`userType=${window.globalVars.userType}`);
    analyticsGetArguments.push(`requestId=${window.globalVars.requestId}`);
    analyticsGetArguments.push(`hhid=${window.globalVars.hhid}`);
    analyticsGetArguments.push(`storageDetected=${storageDetected}`);
    analyticsGetArguments.push(`domElementsDetected=${domElementsDetected}`);
    analyticsGetArguments.push(`extensionsDetected=${extensionName}`);
    new Image().src = `/analytics?${analyticsGetArguments.join('&')}`;
}

let observer;
function callback(mutationsList) {
    for (const mutation of mutationsList) {
        for (let i = 0; i < mutation.addedNodes.length; ++i) {
            const addedNode = mutation.addedNodes[i];
            if (addedNode.nodeType !== Node.ELEMENT_NODE) {
                // eslint-disable-next-line no-continue
                continue;
            }
            for (const ext in extensions) {
                if (addedNode.id === extensions[ext] || addedNode.classList.contains(extensions[ext])) {
                    domElementsDetected = ext;
                    sendAnalytics({ storageDetected, domElementsDetected });
                    observer.disconnect();
                    return;
                }
            }
        }
    }
}
export default () => {
    if (!extensions) {
        return;
    }
    if (storageDetected || domElementsDetected) {
        sendAnalytics({ storageDetected, domElementsDetected });
    } else {
        observer = new MutationObserver(callback);
        observer.observe(document.body, { childList: true, subtree: true });
    }
};
