import * as Sentry from '@sentry/react';

import Debug from 'HHC/Debug';

/**
 * Модуль регистрирует обработчик для отладочной утилиты {@link module:HHC/Debug},
 * служит для отсылки отладочной информации на сторонний сервис (на даннй момент - Sentry) <br>
 *
 * Имя обработчика - "out", Уровень логирования - PRODUCTION
 *
 * @example
 * Debug = require('HHC/Debug');
 * Debug.log('out', 'Ошибка!', {explain: 'Отладочное свойство'})
 * //Выводим и отсылаем полученный объект ошибки
 * Debug.log('out error', error, {explain: 'Тут получили ошибку.'})
 *
 * @param {Error|String} error  Объект ошибки или сообщение для отсылки.
 * @param {Object}       [data] Объект с вспомогательными свойствами.
 *
 * @type {Function}
 * @exports HHC/Debug/Outer
 */
const handler = function (error, data = {}) {
    if (!window.globalVars.features.sentry_logging) {
        return;
    }
    const { sentryFingerprint, ...extra } = data;
    Sentry.withScope((scope) => {
        scope.setExtra('fromDebug', true);
        if (sentryFingerprint) {
            scope.setFingerprint(sentryFingerprint);
        }
        if (extra instanceof Object) {
            scope.setExtras(extra);
        }
        Sentry.captureException(error);
    });
};
Debug.registerLog('out', Debug.viewMods.PRODUCTION, handler);
