import urlParser from 'bloko/common/urlParser';

const PHONE_VERIFICATION_URL = '/account/phone_verification';
export const PHONE_VERIFICATION_ERROR = 'phone_verification_required';

export const isAtPhoneVerificationPage = () => {
    return location.pathname.includes(PHONE_VERIFICATION_URL);
};

export const redirectToPhoneVerification = () => {
    const phoneVerificationUrl = urlParser(PHONE_VERIFICATION_URL);
    phoneVerificationUrl.params.backurl = [location.pathname + location.search + location.hash];
    location.assign(phoneVerificationUrl.href);
};

const PhoneVerificationInterceptor = (error) => {
    if (
        error.response?.status === 403 &&
        error.response?.data?.error === PHONE_VERIFICATION_ERROR &&
        !isAtPhoneVerificationPage()
    ) {
        redirectToPhoneVerification();
    }

    return Promise.reject(error);
};

export default PhoneVerificationInterceptor;
