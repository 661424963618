export default (componentName) =>
    new Promise((resolve, reject) => {
        // eslint-disable-next-line import/no-dynamic-require
        import(
            /* webpackExclude: /\/bloko\// */
            /* webpackExclude: /\.test/ */
            /* webpackMode: "lazy" */
            './../' + componentName // eslint-disable-line prefer-template
        ).then((module) => {
            return resolve(module.default);
        }, reject);
    });
