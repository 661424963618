import urlParser from 'bloko/common/urlParser';

export const HHTM_SOURCE_HEADER = 'X-hhtmSource';
export const HHTM_FROM_HEADER = 'X-hhtmFrom';

export const getHhtmHeaders = (
    url: string
):
    | {
          [HHTM_SOURCE_HEADER]: string;
          [HHTM_FROM_HEADER]: string;
      }
    | object => {
    const parsedUrl = urlParser(url);
    // Access to XMLHttpRequest at 'https://hhcdn.ru/pv' from origin 'https://hh.ru'
    // has been blocked by CORS policy: Request header field x-hhtmsource is not allowed
    // by Access-Control-Allow-Headers in preflight response.
    if (parsedUrl.hostname && parsedUrl.hostname !== window.location.hostname) {
        return {};
    }
    return {
        [HHTM_SOURCE_HEADER]: window.globalVars.analyticsParams.hhtmSource,
        [HHTM_FROM_HEADER]: window.globalVars.analyticsParams.hhtmFrom,
    };
};
