import $ from 'jquery';

import scrollToElement from 'Utils/ScrollToElement';
// Компонент корректирует браузерный скролл к #fragment элементам. Он создан из-за того, что при скролле к
// элементу необходимо учитывать Sticky, которые прилипли к верху экрана.
function scrollToHash() {
    const element = determineHashElement();
    if (element) {
        scrollToElement(element, {
            speed: 0,
            topOffset: 0,
            centered: false,
        });
    }
}
function determineHashElement() {
    let $element;
    try {
        $element = $(window.location.hash);
    } catch (error) {
        // Невалидный fragment в урле, который не является селектором == игнорируем.
        return null;
    }
    if ($element.length !== 0) {
        return $element.get(0);
    }
    return $(`a[name="${window.location.hash.slice(1)}"]`).get(0);
}

const scrollAfterLoad = () => {
    window.setTimeout(() => {
        scrollToHash();
    }, 0);
};

export default function () {
    $(window).on('hashchange', scrollToHash);
    if (window.location.hash === '') {
        return;
    }
    // Скролим к якорю после браузерного скролла, с поправкой на высоту Sticky-элементов
    if (document.readyState === 'complete') {
        scrollToHash();
        return;
    }
    // Этот хак нужен для того, чтобы не дать браузеру отскроллить туда, где был вьюпорт, если человек
    // открыл страницу с #якорем, проскроллил и обновил ее. Происходит сразу после браузерного скролла.
    window.addEventListener('load', scrollAfterLoad);
}
