import urlParser from 'bloko/common/urlParser';

const xsrfInterceptor = (config) => {
    const parsedUrl = urlParser(config.url);

    if (!parsedUrl.hostname || parsedUrl.hostname === window.location.hostname) {
        config.xsrfCookieName = '_xsrf';
        config.xsrfHeaderName = 'X-Xsrftoken';
    }
    return config;
};

export default xsrfInterceptor;
